import { PAIS_CACHE } from 'src/config'
import { sessionData } from 'src/store/user'
import { get } from 'svelte/store'

let ip = ''
export const DEFAULT_QUERY_PARAMS = {
  paiscache: PAIS_CACHE,
  get ip() {
    if (ip) return ip
    ip = get(sessionData)?.ipCliente || ''
    return ip
  }
}