import http from 'src/helpers/http'
import { createUrl } from 'src/helpers/utils'
import { footer, header } from 'src/store/cma'
import { get } from 'svelte/store'
import { DEFAULT_QUERY_PARAMS } from './constants'
import type { DameUrl } from 'src/middlewares/render-dame-url'
import { PAIS_CACHE } from 'src/config'
import { isEqual } from '@chasi/ui/utils'
import { page } from '@chasi/manager/client/shared'

export type CookieTable = {
  nombreGrupo: string
  numeroColumnas: number
  subgrupoCookies: Array<{
    nombreSubgrupo: string
    numeroColumnas: number
    cookies: Array<{
      nombre: string
      descripcion: string
      propietario?: string
      dominio: string
      caducidad: string
    }>
  }>
}

export async function fetchCookiesTable() {
  const url = createUrl('/rgpd/cookies', DEFAULT_QUERY_PARAMS)
  const { grupoCookies } = await http.get<{ grupoCookies: CookieTable[] }>(url)
  return grupoCookies
}

export async function fetchRGPD(tipo: IntRange<1, 12>) {
  const url = createUrl('/rgpd/textosrgpd2', { ...DEFAULT_QUERY_PARAMS, tipo })
  const rgpdData = await http.get<RGPDResponse>(url)
  return rgpdData
}

export async function updateRGPD(rgpd?: RGPD) {
  if (!rgpd) return
  if (!rgpd.email) return
  await http.post('/rgpd/actualizargpd', rgpd)
}

export async function fetchHeader() {
  try {
    if (get(header)) return
    const url = createUrl('/comun/cabecera', DEFAULT_QUERY_PARAMS)
    const headerData = await http.get<CDLHeader>(url)
    header.set(headerData)
  } catch (error) {
    console.log(error)
  }
}

export async function fetchFooter() {
  try {
    if (get(footer)) return
    const url = createUrl('/comun/pie', DEFAULT_QUERY_PARAMS)
    const footerData = await http.get<CDLFooter>(url)
    footer.set(footerData)
  } catch (error) {
    console.log(error)
  }
}

let promocionesNewsletter: PromoNewsletter | undefined
export async function fetchPromosNesletter() {
  if (promocionesNewsletter) return promocionesNewsletter
  const url = createUrl('/componente/getPromocionNewsletter', DEFAULT_QUERY_PARAMS)
  promocionesNewsletter = await http.get<PromoNewsletter>(url)
  return promocionesNewsletter
}

export async function fetchComponentData<T>(content: T, queryParams: Record<string, any> = {}) {
  const currentPage = get(page)
  const { resolvedComponents, requestUri } = currentPage.state as DameUrl
  const componentData = resolvedComponents.find(c => isEqual(c.content, content))
  if (!componentData) throw new Error('El componente no se encuentra en la pagina actual')
  const { idTipoPlantilla, identificador, idComponente, id } = componentData
  const prefix = componentPrefix(id, idTipoPlantilla)
  const url = createUrl(prefix, {
    paiscache: PAIS_CACHE,
    identificador,
    idcomponente: idComponente,
    ...queryParams,
    requesturi: buildRequestUri(id, requestUri, queryParams)
  })
  const newContent = await http.get<T>(url)
  //@ts-ignore
  componentData.content = newContent
  return newContent
}

function componentPrefix(id: number, idTipoPlantilla?: number) {
  switch (id) {
    case 245: return '/buscador/busqueda'
    case 259:
    case 201: return `/buscador/componente/${idTipoPlantilla}`
    default: return `/comun/componente/${idTipoPlantilla}`
  }
}
function buildRequestUri(...args: [id: number, ...Parameters<typeof createUrl>]) {
  const [id, requesturi, queries] = args
  const decodedUri = decodeURIComponent(requesturi)
  switch (id) {
    case 245: return createUrl(decodedUri, { ...queries, productoLocal: true })
    // remover ?encuadernacion por defecto que llega en el requesturi
    case 259: return createUrl(decodedUri, queries, ['encuadernacion'])
    case 201: return createUrl(decodedUri, queries)
    default: return undefined
  }
}

export function fetchBannerConfirmacionCompra() {
  const url = createUrl('/comun/componentesConfirmacionCompra', DEFAULT_QUERY_PARAMS)
  return http.get<Banner[]>(url)
}


// solo tenemos esto
// nosotros.nuestras-tiendas-nuestrastiendas.textonumerolibrerias
export async function fetchTexts(key: string) {
  try {
    const url = createUrl('/comun/getTextosFijos', { ...DEFAULT_QUERY_PARAMS, clavetexto: key })
    const res = await http.get<string>(url)
    return res
  } catch (error) {
    return ''
  }
}