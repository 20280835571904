import { CNotifier } from '@chasi/ui'
import { PAIS_CACHE } from 'src/config'
import http from 'src/helpers/http'
import { createUrl, handleValidationErrors, toStringQueries } from 'src/helpers/utils'
import { DEFAULT_QUERY_PARAMS } from './constants'
import { formasDePago, compraUnPaso, user, userMember, type EstadoCompraUnPaso, type FormasDePagoUsuario, type EstadoCDLPlus, CDLPlus, shippingAddressUser, invoiceAddressUser, formasEnvios, type FormasDeEnvio } from 'src/store/user'

export async function fetchUserData() {
  try {
    const url = createUrl('/usuario/getDatosUsuario', DEFAULT_QUERY_PARAMS)
    const userData = await http.get<User>(url)
    user.set(userData)
  } catch (error) {
    CNotifier.error('Ocurrió un error obteniendo tus datos, intentelo más tarde')
  }
}

export async function fetchMemberData() {
  try {
    const url = createUrl('/usuario/datosSocio', DEFAULT_QUERY_PARAMS)
    const memberData = await http.post<UserMember>(url)
    userMember.set(memberData)
  } catch (error) {
    CNotifier.error('Ocurrió un error obteniendo tus datos, intentelo más tarde')
  }
}

export async function subscribeNewsletter(email: string, rgpd: RGPD) {
  try {
    await http.post('/usuario/guardarsuscripcionboletin', {
      datos: {
        ...rgpd,
        email
      },
      idPaisOnix: PAIS_CACHE
    })
    CNotifier.success('Suscripción realizada correctamente')
  } catch (error) {
    CNotifier.error('Ocurrió un error, intentelo más tarde')
  }
}

export async function upgradeToMember(telefono: string) {
  const url = createUrl('/usuario/altaSocioLo', DEFAULT_QUERY_PARAMS)
  const { mensaje, code } = await http.post<{ mensaje: string, code: number }>(url, { telefono })
  if (code !== 0) {
    await fetchMemberData()
    CNotifier.success(mensaje)
  } else {
    CNotifier.error(mensaje)
    throw new Error(mensaje)
  }
}

type AddMemberResponse = {
  code: number
  mensaje: string
}
const opsLeo = {
  addLeo: '/usuario/altaSociosInfantiles',
  updateLeo: '/usuario/modificacionSociosInfantiles',
  removeMember: '/usuario/bajaSocioInfantilJoven',
  addJoven: '/usuario/altaSociosJuveniles',
  updateJoven: '/usuario/modificacionSociosJuveniles'
} as const
export async function updateMemberDependant(operation: keyof typeof opsLeo, members: MemberDependant[], firma?: string, esatcactiva = false) {
  try {
    const url = createUrl(opsLeo[operation], { ...DEFAULT_QUERY_PARAMS, esatcactiva: `${esatcactiva}` })
    const bodyParam = operation === 'removeMember' ? members[0] : { sij: members, firmaShop: firma }
    const res = await http.post<AddMemberResponse>(url, bodyParam)
    if (res.code === 0)
      CNotifier.error(res.mensaje)
    else
      CNotifier.success(res.mensaje)
    await fetchMemberData()
  } catch (error) {
    CNotifier.error('Ocurrió un error, intentelo más tarde')
  }
}

export async function setFavoriteStore(storeId: number) {
  const url = createUrl('/usuario/cambiolibreriasocio', { ...DEFAULT_QUERY_PARAMS, idlibreria: storeId })
  await http.post(url)
  await fetchMemberData()
}

export async function updateUserPersonalInfo(userData: User) {
  try {
    const url = createUrl('/usuario/modificarDatosUsuario', DEFAULT_QUERY_PARAMS)
    const { nombre, apellidos, nickName, email, mostrarAlias, numLibros, sobreMi, anyo, mes, dia } = userData
    const visualizarNombre = !mostrarAlias
    await http.post(url, { nombre, apellidos, nickName, email, mostrarAlias, numLibros, sobreMi, anyo, mes, dia, visualizarNombre })
    await fetchUserData()
    CNotifier.success('Se ha actualizado correctamente')
  } catch (error) {
    CNotifier.error('No se pudo actualizar la informacíon')
  }
}

export async function fetchCDLPlusStatus() {
  const url = createUrl('/usuario/visualizarEstadoCDLPlus', DEFAULT_QUERY_PARAMS)
  const res = await http.get<EstadoCDLPlus>(url)
  CDLPlus.set(res)
}

export async function confirmCDLPlusPaycomet(querie: ParsedUrlQuery) {
  try {
    const paycometParam = toStringQueries(querie)
    const url = createUrl('/usuario/confirmarcdlpluspaycomet', { ...DEFAULT_QUERY_PARAMS, ...paycometParam })
    const res = await http.post<EstadoCDLPlus>(url)
    CDLPlus.set(res)
    if (res.mensaje) {
      CNotifier.success(res.mensaje)
    }
  } catch (error) { }
}

export async function renovateCDLPlus(renewActivated: boolean) {
  try {
    const renovar = renewActivated ? 0 : 1
    const url = createUrl('/usuario/renovarAutomaticamenteCDLPlus', { ...DEFAULT_QUERY_PARAMS, renovar })
    await http.post(url)
    if (renovar === 0) {
      CNotifier.success('Se ha desactivado la renovación automática')
    } else {
      CNotifier.success('Se ha activado la renovación automática')
    }
    await fetchCDLPlusStatus()
  } catch (error) {
    CNotifier.error('Ha ocurrido un error')
  }
}

type RecargarSaldoResponse = {
  statusCode: number
  message: string
  importeSaldo: number
}

export async function recargarSaldo(cardCode: string) {
  const url = createUrl('/usuario/recargaSaldo', DEFAULT_QUERY_PARAMS)
  const { statusCode, message } = await http.post<RecargarSaldoResponse>(url, { numTarjeta: cardCode })
  if (message) {
    if (statusCode && statusCode === 1) {
      CNotifier.success(message)
      await getFormasPago()
    } else {
      CNotifier.error(decodeHtmlEntities(message))
    }
  }
}
function decodeHtmlEntities(message: string) {
  const textArea = document.createElement('textarea')
  textArea.innerHTML = message
  return textArea.value
}
export async function getFormasPago() {
  try {
    const url = createUrl('/usuario/getFormasPago', DEFAULT_QUERY_PARAMS)
    const res = await http.post<FormasDePagoUsuario>(url)
    formasDePago.set(res)
  } catch (error) {
    CNotifier.error('Lo sentimos, no hemos podido recuperar tus formas de pago en este momento. Por favor, intenta nuevamente más tarde.')
  }
}

export async function getStatusCompraUnPaso() {
  const url = createUrl('/usuario/visualizar1click', DEFAULT_QUERY_PARAMS)
  const res = await http.get<EstadoCompraUnPaso>(url)
  compraUnPaso.set(res)
}

const UN_CLICK_OPTS = {
  activar: '/usuario/activar1click',
  desactivar: '/usuario/desactivar1click',
} as const
export async function updateCompraUnClick(method: keyof typeof UN_CLICK_OPTS) {
  try {
    const url = createUrl(UN_CLICK_OPTS[method], DEFAULT_QUERY_PARAMS)
    const { codigo, mensaje } = await http.post<{ codigo: number, mensaje: string }>(url)
    if (codigo !== 1) {
      CNotifier.error(mensaje)
    } else {
      CNotifier.success(mensaje)
      await getStatusCompraUnPaso()
    }
  } catch (error) {
    CNotifier.error('Ha ocurrido un error')
  }
}

const CDL_PLUS_OPTS = {
  activar: '/usuario/activarCDLPlus',
  desactivar: '/usuario/desactivarCDLPlus',
} as const
export async function updateCDLPLUS(method: keyof typeof CDL_PLUS_OPTS) {
  try {
    const url = createUrl(CDL_PLUS_OPTS[method], DEFAULT_QUERY_PARAMS)
    const res = await http.post<EstadoCDLPlus>(url)
    if (res.mensaje) CNotifier.error(res.mensaje)
    if (res.url) location.href = res.url
    CDLPlus.set(res)
  } catch (error) {
    CNotifier.error('Ha ocurrido un error')
  }
}
export async function fetchShippingAddress() {
  const url = createUrl('/usuario/direccionesEnvioUsuario', DEFAULT_QUERY_PARAMS)
  const res = await http.get<DireccionEnvio[] | undefined>(url)
  if (res) shippingAddressUser.set(res)
}

export async function fetchInvoiceAddress() {
  const url = createUrl('/usuario/direccionesFacturacionUsuario', DEFAULT_QUERY_PARAMS)
  const res = await http.get<DireccionEnvioFactura[] | undefined>(url)
  if (res) {
    if (res[0]) {
      res[0].seleccionado = true
    }
    invoiceAddressUser.set(res)
  }
  return res
}

export async function fetchRecogidaAddress() {
  const url = createUrl('/usuario/direccionesRecogidaUsuario', DEFAULT_QUERY_PARAMS)
  const res = await http.get<DireccionEnvio[] | undefined>(url)
  return res
}
type UpdateAddressResponse = {
  ok: boolean,
  erroresValidacion: ErroresValidacion
}
const UPDATE_ADDR_OPTS = {
  add: '/usuario/anadirDireccionEnvioUsuario',
  modify: '/usuario/modificarDireccionUsuario',
  remove: '/usuario/eliminarDireccionUsuario',
  main: '/usuario/convertirDireccionEnPrincipal',
} as const
export async function updateShippingAddress(action: keyof typeof UPDATE_ADDR_OPTS, address: DireccionEnvioNueva | DireccionEnvio) {
  try {
    const url = createUrl(UPDATE_ADDR_OPTS[action], DEFAULT_QUERY_PARAMS)
    const { ok, erroresValidacion } = await http.post<UpdateAddressResponse>(url, address)
    if (ok) {
      await fetchShippingAddress()
    } else if (erroresValidacion) {
      handleValidationErrors(erroresValidacion)
    } else {
      CNotifier.error('No se pudo actualizar la dirección')
    }
  } catch (error: any) {
    if (error.errorCode === 126) {
      CNotifier.error(error.message)
    } else {
      CNotifier.error('Ha ocurrido un error')
    }
  }
}

const UPDATE_INVOICE_OPTS = {
  add: '/usuario/anadirDireccionFacturacionUsuario',
  modify: '/usuario/modificarDireccionUsuario'
} as const
export async function updateInvoiceAddress(action: keyof typeof UPDATE_INVOICE_OPTS, address: DireccionEnvioNueva | DireccionEnvio) {
  try {
    const url = createUrl(UPDATE_INVOICE_OPTS[action], DEFAULT_QUERY_PARAMS)
    const { ok, erroresValidacion } = await http.post<UpdateAddressResponse>(url, address)
    if (ok) {
      const dirs = await fetchInvoiceAddress()
      if (action === 'add' && dirs) {
        await http.post(createUrl('/usuario/convertirDireccionEnPrincipal', DEFAULT_QUERY_PARAMS), dirs[0])
      }
    } else if (erroresValidacion) {
      handleValidationErrors(erroresValidacion)
    } else {
      CNotifier.error('No se pudo actualizar la dirección')
    }
  } catch (error: any) {
    if (error.errorCode === 126) {
      CNotifier.error(error.message)
    } else {
      CNotifier.error('Ha ocurrido un error')
    }
  }
}

export async function fetchFormasEnvio() {
  const url = createUrl('/usuario/getFormasEnvioUsuario', DEFAULT_QUERY_PARAMS)
  const res = await http.get<FormasDeEnvio[]>(url)
  formasEnvios.set(res)
}

export async function updateFormaEnvio(id: number) {
  const url = createUrl('/usuario/modificarMetodoEnvioUsuario', { ...DEFAULT_QUERY_PARAMS, idmetodoenvio: id })
  const ok = await http.get<boolean>(url)
  if (ok) {
    await fetchFormasEnvio()
    CNotifier.success('Modo de envío actualizado')
  } else {
    CNotifier.error('Ha ocurrido un error')
  }
}

type UpdateCardResponse = {
  ok: boolean,
  erroresValidacion: ErroresValidacion
}

const UPDATE_CARD_OPT = {
  add: {
    url: '/usuario/guardarFormaPago',
    successMessage: 'Tarjeta agregada',
    errorMessage: 'Error al guardar la forma de pago'
  },
  modify: {
    get url() { return UPDATE_CARD_OPT.add.url },
    successMessage: 'Cambio tarjeta principal',
    get errorMessage() { return UPDATE_CARD_OPT.add.errorMessage },
  },
  remove: {
    url: '/usuario/borrarFormaPago',
    successMessage: 'Se ha eliminado una tarjeta',
    errorMessage: 'Error al eliminar la forma de pago'
  }
} as const

export async function updateCreditCard(action: keyof typeof UPDATE_CARD_OPT, pago: Partial<MedioPagoClient>) {
  try {
    const url = createUrl(UPDATE_CARD_OPT[action].url)
    // const url = createUrl(UPDATE_CARD_OPT[action].url, DEFAULT_QUERY_PARAMS)
    const cardData = {
      idFormaPago: pago.idFormaPago,
      titular: pago.titular,
      mesCaducidad: pago.mes,
      anoCaducidad: pago.anyo,
      paytpvToken: pago.paytpvToken,
      esPrincipal: pago.esPrincipal
    }
    const { ok, erroresValidacion } = await http.post<UpdateCardResponse>(url, {
      ...cardData,
      ipAddress: DEFAULT_QUERY_PARAMS.ip,
      paisCache: DEFAULT_QUERY_PARAMS.paiscache
    })
    if (ok) {
      await getFormasPago()
      CNotifier.success(UPDATE_CARD_OPT[action].successMessage)
    } else if (erroresValidacion) {
      handleValidationErrors(erroresValidacion)
    } else {
      CNotifier.error(UPDATE_CARD_OPT[action].errorMessage)
    }
  } catch (error) {
    CNotifier.error(UPDATE_CARD_OPT[action].errorMessage)
  }
}

export function postOpinion(estrellas: number, idProducto: number, opinion: string) {
  const url = createUrl('/opiniones/setOpinionProducto', DEFAULT_QUERY_PARAMS)
  return http.post(url, {
    estrellas,
    idProducto,
    opinion
  })
}

export type ReportOpinionParams = {
  nombre: string,
  apellidos: string,
  email: string,
  motivosDenuncia: string,
  motivo: number,
  idOpinion: number
}
export async function reportOpinion(params: ReportOpinionParams) {
  try {
    const url = createUrl('/opiniones/setDenunciaOpinion', DEFAULT_QUERY_PARAMS)
    await http.post(url, params)
    CNotifier.success('Reporte envíado')
  } catch (error) {
    CNotifier.success('Ha ocurrido un error al envíar el reporte, intentelo en otro momento')
  }
}

type MotivosBaja = {
  tipoMotivo: number,
  descripcionTipo: string,
  permiteTexto: boolean
}
export function fetchMotivosBaja() {
  const url = createUrl('/usuario/motivosbajacomusocio', DEFAULT_QUERY_PARAMS)
  return http.get<MotivosBaja[]>(url)
}

export async function enviarMotivosBaja(email: string, motivos: { tipoMotivo: number, textoMotivo?: string }[]) {
  try {
    if (!motivos.length) return CNotifier.error('Debes marcar al menos una casilla o escribir una sugerencia')
    const params = {
      idPaisOnix: DEFAULT_QUERY_PARAMS.paiscache,
      email,
      motivos
    }
    const { ok, mensaje } = await http.post<{ mensaje: string, ok: boolean }>('/usuario/bajacomusocio', params)
    if (ok) {
      CNotifier.success(mensaje)
    } else {
      CNotifier.error(mensaje)
    }
  } catch (error) {
    CNotifier.error('Ha ocurrido un error al dar de baja, intentelo más tarde')
  }
}

export type AlarmaUsuarios = {
  id: number
  idTipo: number
  nombreTipo: string
  contenido: string
}
export async function fetchAlarmasUsuarios(email: string) {
  const urlAutores = createUrl('/alarmas/getalarmas/5', { ...DEFAULT_QUERY_PARAMS, email })
  const urlTemas = createUrl('/alarmas/getalarmas/6', { ...DEFAULT_QUERY_PARAMS, email })
  const [resAuthors, resThemes] = await Promise.all([http.get<AlarmaUsuarios[]>(urlAutores), http.get<AlarmaUsuarios[]>(urlTemas)])
  return [...resAuthors, ...resThemes]
}

export function fetchAlarmasProducto(codigo: string) {
  const url = createUrl(`/alarmas/getalarma/${codigo}`, DEFAULT_QUERY_PARAMS)
  return http.get<AlarmaUsuarios[]>(url)
}

type BajaAlarmaParams = {
  email: string
  todas: boolean
  tipo: number
  alarmas: AlarmaUsuarios[]
}
export async function bajaAlarma(data: BajaAlarmaParams) {
  try {
    if (!data.alarmas.length) return CNotifier.error('Debes marcar al menos una alarma')
    const params = {
      ...data,
      idPaisOnix: DEFAULT_QUERY_PARAMS.paiscache
    }
    const { code, mensaje } = await http.post<{ code: number, mensaje: string }>('/alarmas/bajaalarmas', params)
    if (code !== 0) return CNotifier.error(mensaje)
    CNotifier.success(mensaje)
  } catch (error) {
    CNotifier.error('Ha ocurrido un error, intentelo más tarde')
  }
}

/**
* @param tipos separados por "|" (pipe)
*/
export async function bajaNewsletters(email: string, tipos: string) {
  if (!tipos) return CNotifier.error('Debe elegir al menos un boletín para dar de baja las comunicaciones')
  const { ok, mensaje } = await http.post<{ ok: boolean, mensaje: string }>('/usuario/bajanewsletter', {
    datos: { email },
    idPaisOnix: DEFAULT_QUERY_PARAMS.paiscache,
    tipos
  })
  if (ok) CNotifier.success(mensaje)
  else CNotifier.error(mensaje)
}

export function fetchSectoresEmpresa() {
  const url = createUrl('/usuario/getsectoresempresa', DEFAULT_QUERY_PARAMS)
  return http.get<{ id: string, nombre: string }[]>(url)
}

export async function updateMemberPhone(telefono: string) {
  const url = createUrl('/usuario/modtelsoc', DEFAULT_QUERY_PARAMS)
  const { code, mensaje } = await http.post<{ code: number, mensaje: string }>(url, { telefono })
  if (code !== 1) {
    CNotifier.error(mensaje)
  } else {
    await fetchMemberData()
    CNotifier.success(mensaje)
  }
}