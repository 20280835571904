import { API_URL } from 'src/config'
import { NativeFetchWrapper } from 'src/lib/fetchWrapper'

const ssr = import.meta.env.SSR
const prefix = ssr ? API_URL : '/cdlweb/api'
const http = new NativeFetchWrapper(prefix, {
  headers: {
    'content-type': 'application/json'
  }
})

export default http