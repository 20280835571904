import { logout } from 'src/services/auth'
import { articulos } from '../store/checkout'
import { userMember, userName } from '../store/user'
import { APP_MODE } from 'src/config'

if (import.meta.env.SSR) throw new Error('esto no se debe cargar en el servidor')

export function exposeToMobileApp() {
  if (!APP_MODE) return
  userMember.subscribe(u => {
    if (u) {
      exposeData('cdl:user-lvl', u.nivelSocio)
    }
  })
  userName.subscribe(name => {
    exposeData('cdl:user-name', name)
  })
  articulos.subscribe(amount => {
    exposeData('cdl:cart-articulos', amount)
  })

  window.addEventListener('app:logout', async () => {
    await logout()
    exposeData('cdl:user-logout')
  })
  window.addEventListener('message', async (e) => {
    if (typeof e.data === 'string' && e.data.includes('btnLogOutapp')) {
      await logout()
      exposeData('cdl:user-logout')
    }
  })
}

function exposeData<T>(name: string, data?: T) {
  window.dispatchEvent(new CustomEvent(name, { detail: data }))
  parent.postMessage({ name, detail: data }, '*')
}

// Como lo debe usar reskit

// window.addEventListener('message', e => {
//   try {
//     if (!e.data.name) return
//     console.log(e.data.name, e.data.detail) // cdl:user-name, pepito
//   } catch (err) {
//     console.log(err)
//   }
// })

// const logEv = (nombre: string) => (e: CustomEvent<any>) => console.log({nombreEvento: nombre, datosDeEvento: e.detail})
// window.addEventListener('cdl:user-lvl', logEv('cdl:user-lvl')) // nivel de socio: 0 | 1 | 2 |3
// window.addEventListener('cdl:user-name', logEv('cdl:user-name')) // nombre de usuario: string
// window.addEventListener('cdl:cart-articulos', logEv('cdl:cart-articulos')) // articulos en la cesta: number
// window.addEventListener('cdl:user-logout', logEv('cdl:user-logout')) // usuario se ha deslogado